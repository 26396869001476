import { entitySelectors } from '../entities/entities';

export const getProduct = (state, productId) =>
  entitySelectors.getEntity(state.entities.product, productId);

export const getProductIdsForPerformance = (state, performanceId) =>
  state.products.byPerformanceId[performanceId]
    ? state.products.byPerformanceId[performanceId].ids
    : [];
export const getProducts = (state, performanceId) =>
  getProductIdsForPerformance(state, performanceId).map(id =>
    getProduct(state, id)
  );

export const getLoading = (state, performanceId) =>
  state.products.byPerformanceId[performanceId]
    ? state.products.byPerformanceId[performanceId].isLoading
    : false;
