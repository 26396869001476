import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import analyticsMiddleware from '../middleware/analyticsMiddleware';
import rootReducer from '../modules';

const configureStore = (history, initialState) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        analyticsMiddleware
      )
    )
  );

  store.runSaga = sagaMiddleware.run;
  return store;
};
export default configureStore;
