import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import { fork, all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import entities from './entities/entities';

import performances, {
  performanceOperations
} from './performances/performances';
import basket from './basket/basket';
import tickets, { ticketOperations } from './tickets/tickets';
import reservation, { reservationOperations } from './reservation/reservation';
import auth, { authOperations } from './auth/auth';
import order, { orderOperations } from './order/order';
import cards, { cardOperations } from './cards/cards';
import discount, { discountOperations } from './discount/discount';
import products, { productOperations } from './products/products';
import specificRequirements from './specificRequirements/specificRequirements';

export function* rootSaga() {
  yield all([
    fork(performanceOperations.watchLoadPerformances),
    fork(performanceOperations.watchLoadPerformance),
    fork(ticketOperations.watchLoadTickets),
    fork(reservationOperations.watchCreateReservation),
    fork(reservationOperations.watchCreateReservationSuccess),
    fork(authOperations.watchLogin),
    fork(authOperations.watchLogOut),
    fork(authOperations.watchSendPasswordReset),
    fork(authOperations.watchRegister),
    fork(authOperations.watchLoadAffiliate),
    fork(authOperations.watchLoadCustomer),
    fork(authOperations.watchTrackAffiliate),
    fork(authOperations.watchLoadCurrentUser),
    fork(orderOperations.watchCreateOrder),
    fork(cardOperations.watchLoadCards),
    fork(cardOperations.watchCreateCard),
    fork(cardOperations.watchDeleteCard),
    fork(discountOperations.watchAddDiscountCode),
    fork(routinePromiseWatcherSaga),
    fork(productOperations.watchFetchProducts)
  ]);
}

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    entities,
    performances,
    tickets,
    basket,
    reservation,
    auth,
    order,
    cards,
    discount,
    products,
    specificRequirements
  });

export default rootReducer;
