import { takeEvery } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { fetchEntity } from '../shared/operations';

export const fetchProducts = fetchEntity.bind(
  null,
  actions.fetchProducts,
  api.fetchProducts
);

export function* watchFetchProducts() {
  yield takeEvery(actions.fetchProducts.TRIGGER, fetchProducts);
}
