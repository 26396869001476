import { schema, normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import fetch from 'isomorphic-fetch';
import buildUrl from 'build-url';
import { ApiError, NetworkError } from '../utils/errors';

let API_ROOT;
let PUBLIC_KEY;

switch (process.env.REACT_APP_API_VERSION) {
  case 'development':
    API_ROOT = process.env.REACT_APP_API_ROOT_DEV;
    PUBLIC_KEY = process.env.REACT_APP_PUBLIC_API_KEY_DEV;
    break;
  case 'testing':
    API_ROOT = process.env.REACT_APP_API_ROOT_TESTING;
    PUBLIC_KEY = process.env.REACT_APP_PUBLIC_API_KEY_TESTING;
    break;
  case 'live':
    API_ROOT = process.env.REACT_APP_API_ROOT_LIVE;
    PUBLIC_KEY = process.env.REACT_APP_PUBLIC_API_KEY_LIVE;
    break;
  default:
    API_ROOT = process.env.REACT_APP_API_ROOT_TESTING;
    PUBLIC_KEY = process.env.REACT_APP_PUBLIC_API_KEY_TESTING;
}

const publicToken = btoa(`${PUBLIC_KEY}:`);

const defaultConfig = authToken => ({
  method: 'GET',
  headers: {
    Authorization: authToken ? `Bearer ${authToken}` : `Basic ${publicToken}`
  }
});

const authConfig = (body, authToken, method = 'POST') => {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken ? `Bearer ${authToken}` : `Basic ${publicToken}`
    },
    body: JSON.stringify(body)
  };
};

export const parseApiError = meta =>
  (meta.errors
    ? meta.errors
    : [{ attribute: '_error', messages: meta.message }]
  ).reduce(
    (obj, err) => {
      if (Array.isArray(err.messages)) {
        obj[err.attribute] = err.messages.join(' ');
      } else if (typeof err.messages === 'object') {
        obj[err.attribute] = parseApiError(err.messages);
      } else {
        obj[err.attribute] = err.messages;
      }
      return obj;
    },
    { _error: 'There was an error submitting the form' }
  );

const fullUrl = (endpoint, queryParams) => {
  return buildUrl(API_ROOT, {
    path: endpoint,
    queryParams: queryParams
  });
};

const callApi = (endpoint, schema, config = defaultConfig(), queryParams) => {
  const url =
    endpoint.indexOf(API_ROOT) === -1
      ? fullUrl(endpoint, queryParams)
      : endpoint;
  return fetch(url, config)
    .then(
      response => response.json().then(json => ({ json, response })),
      err => Promise.reject(new NetworkError())
    )
    .then(({ json, response }) => {
      if (!response.ok) {
        console.log(json._metadata);
        return Promise.reject(new ApiError(parseApiError(json._metadata)));
      }
      const camelizedJson = camelizeKeys(json.data);
      const pagination = {
        ...camelizeKeys(json._metadata)
      };

      if (schema) {
        return {
          ...normalize(camelizedJson, schema),
          pagination: pagination
        };
      }
      return camelizedJson;
    })
    .then(
      response => ({ response }),
      error => ({ error })
    );
};

// api services
const performanceSchema = new schema.Entity('performance');
const performanceListSchema = [performanceSchema];

export const fetchPerformances = (eventSlug, page) =>
  callApi(
    `v1/event/${eventSlug}/performances/`,
    performanceListSchema,
    defaultConfig(),
    {
      resultsPerPage: 100,
      page: page
    }
  );

export const fetchPerformance = data =>
  callApi(
    `v1/performance/${data.performanceId}/`,
    performanceSchema,
    defaultConfig()
  );

const ticketSchema = new schema.Entity('ticket');
const ticketListSchema = [ticketSchema];

export const fetchTickets = data =>
  callApi(
    `performance/${data.performanceId}/tickets/`,
    ticketListSchema,
    defaultConfig(),
    { resultsPerPage: 100 }
  );

const cardSchema = new schema.Entity('card');
const cardListSchema = [cardSchema];

const reservationSchema = new schema.Entity(
  'reservation',
  {},
  { idAttribute: 'sessionId' }
);

const orderSchema = new schema.Entity('order');

export const createReservation = data =>
  callApi(`reservation/`, reservationSchema, authConfig(data.body));

const customerSchema = new schema.Entity('customer');
export const authorize = authData =>
  callApi('auth/', customerSchema, authConfig(authData));

export const register = data =>
  callApi('v1/customer/', customerSchema, authConfig(data));

export const fetchCustomer = authToken =>
  callApi('v1/customer/', customerSchema, defaultConfig(authToken));

export const createOrder = (data, authToken) =>
  callApi('v1/order/', orderSchema, authConfig(data, authToken));

export const fetchCards = (data, authToken) =>
  callApi('card/', cardListSchema, defaultConfig(authToken));

export const createCard = (data, authToken) =>
  callApi('card/', cardSchema, authConfig(data, authToken));

export const deleteCard = (data, authToken) =>
  callApi(`card/${data.cardId}/`, null, authConfig(data, authToken, 'DELETE'));

export const sendForgotPassword = data =>
  callApi('user/forgot-password/', null, authConfig(data));

export const addDiscountCode = (data, authToken) =>
  callApi(
    `reservation/${data.reservationId}/discount/`,
    reservationSchema,
    authConfig(data, authToken)
  );

const productSchema = new schema.Entity('product');
const productListSchema = [productSchema];

export const fetchProducts = data =>
  callApi(
    `performance/${data.performanceId}/products/`,
    productListSchema,
    defaultConfig(),
    { resultsPerPage: 100 }
  );
