import { combineReducers } from 'redux';
import * as types from './types';

const showSpecificRequirements = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_SPECIFIC_REQUIREMENTS:
      return true;
    case types.HIDE_SPECIFIC_REQUIREMENTS:
    case types.CLEAR_SPECIFIC_REQUIREMENT_MESSAGE:
      return false;
    default:
      return state;
  }
};
const message = (state = [], action) => {
  switch (action.type) {
    case types.ADD_SPECIFIC_REQUIREMENT_MESSAGE:
      return [...state, action.payload.message];
    case types.REMOVE_SPECIFIC_REQUIREMENT_MESSAGE:
      return state.filter(m => m !== action.payload.message);
    case types.CLEAR_SPECIFIC_REQUIREMENT_MESSAGE:
      return [];
    default:
      return state;
  }
};

const specificRequirements = combineReducers({
  showSpecificRequirements,
  message
});

export default specificRequirements;
