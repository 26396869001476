// @flow
import * as React from 'react';
import Instagram from './instagram_icon.png';
import Facebook from './facebook_icon.png';
import Twitter from './twitter_icon.png';

import './DWTFFooter.css';

const Footer = () => (
  <footer className='DWTF-footer'>
    <span className='DWTF-footer-span DWTF-footer-span--copyright'>
      <div className='DWTF-footer-copyright'>
        © Immersive Everywhere 2019-2022
        <br />
        Crafted by{' '}
        <a href='https://lineupnow.com' className='DWTF-footer-copyright-link'>
          Line-Up
        </a>
      </div>
    </span>
    <span className='DWTF-footer-span DWTF-footer-span--links'>
      {/* <a href='/faq' className='DWTF-footer-menu__link'>
        FAQ
      </a>
      <a href='/cast-creative' className='DWTF-footer-menu__link'>
        Cast & Creative
      </a>
      <a href='/merchandise' className='DWTF-footer-menu__link'>
        Merchandise
      </a>
      <a href='/terms' className='DWTF-footer-menu__link'>
        Terms
      </a>
      <a href='/privacy-policy' className='DWTF-footer-menu__link'>
        Privacy
      </a>
      <a href='/cookie-policy' className='DWTF-footer-menu__link'>
        Cookies
      </a> */}
    </span>
    <span className='DWTF-footer-span DWTF-footer-span--social'>
      <div className='social'>
        <a
          className='social__icon'
          href='https://twitter.com/DWtimefracture/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={Twitter} className='social__icon-icon' alt='Twitter' />
        </a>
        <a
          className='social__icon'
          href='https://www.instagram.com/DWtimefracture/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={Instagram} className='social__icon-icon' alt='Instagram' />
        </a>
        <a
          className='social__icon'
          href='https://www.facebook.com/DWtimefracture/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={Facebook} className='social__icon-icon' alt='Facebook' />
        </a>
      </div>
    </span>
  </footer>
);

export default Footer;
