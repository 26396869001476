import React from 'react';
import classNames from 'classnames';
import './FullBackgroundImage.css';

export {
  default as FullBackgroundImageCover
} from './FullBackgroundImageCover/FullBackgroundImageCover';
export {
  default as FullBackgroundImageCoverContentWrap
} from './FullBackgroundImageCoverContentWrap/FullBackgroundImageCoverContentWrap';
export {
  default as FullBackgroundImageCoverContent
} from './FullBackgroundImageCoverContent/FullBackgroundImageCoverContent';

const FullBackgroundImage = ({ children, dw, className, style }) => {
  const FullBackgroundImageContainerClass = classNames(
    'full-image-background-container',
    className,
    {
      'full-image-background-container--dw': dw
    }
  );
  return (
    <div className={FullBackgroundImageContainerClass} style={style}>
      {children}
    </div>
  );
};

export default FullBackgroundImage;
