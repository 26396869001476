import reducer from './reducers';
import * as specificRequirementsSelectors from './selectors';
import * as specificRequirementsActions from './actions';
import * as specificRequirementsOperations from './operations';
import * as specificRequirementsTypes from './types';

export {
  specificRequirementsSelectors,
  specificRequirementsActions,
  specificRequirementsOperations,
  specificRequirementsTypes
};

export default reducer;
