import { createAction } from 'redux-actions';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as types from './types';

export const createOrder = createRoutine(types.CREATE_ORDER);
export const updateOrder = createRoutine(types.UPDATE_ORDER);

export const submitPaymentForm = promisifyRoutine(createOrder);
export const continuePaymentForm = promisifyRoutine(updateOrder);
export const completeOrder = createAction(types.COMPLETE_ORDER);
export const clearOrder = createAction(types.CLEAR_ORDER);
