import { entitySelectors } from '../entities/entities';
export const getToken = state =>
  getCustomer(state) ? getCustomer(state).accessToken : null;
export const isLoggedIn = state => (getToken(state) ? true : false);
export const getDisplayname = state =>
  getCustomer(state) ? getCustomer(state).displayname : null;
export const getForgotPasswordSent = state => state.auth.forgotPassword.isSent;
export const getShouldShowLogin = state => state.auth.showLoginForm;
export const getShouldShowForgotPassword = state =>
  state.auth.showForgotPassword;
export const getCustomerId = state => state.auth.currentUser.customerId;
export const getCustomer = state =>
  entitySelectors.getEntity(state.entities.customer, getCustomerId(state));
export const getAffiliate = state => state.auth.affiliate;
