import { call, put, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { getToken } from '../auth/selectors';

export function* fetchEntity(entity, apiFn, action) {
  const authToken = yield select(getToken);
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(
    apiFn,
    { ...action.payload },
    authToken
  );
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure({ error, ...action.payload }));
}

export function* createEntity(entity, apiFn, action) {
  const authToken = yield select(getToken);
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(
    apiFn,
    { ...action.payload },
    authToken
  );
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure({ error, ...action.payload }));
}

export function* submitForm(entity, apiFn, action) {
  const authToken = yield select(getToken);
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(
    apiFn,
    { ...action.payload },
    authToken
  );
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure(new SubmissionError(error.errors)));
  yield put(entity.fulfill());
}

export function* deleteEntity(entity, apiFn, action) {
  const authToken = yield select(getToken);
  yield put(entity.request());
  const { response, error } = yield call(
    apiFn,
    { ...action.payload },
    authToken
  );
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure({ error, ...action.payload }));
}
