import { useRef, useEffect } from 'react';

const CookieDeclaration = () => {
  const scriptContainer = useRef(null);
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://consent.cookiebot.com/760529c1-ac58-4b5e-acad-2ff32c26bfdb/cd.js';
    script.async = true;
    script.id = 'CookieDeclaration';
    script.type = 'text/javascript';
    scriptContainer.current.appendChild(script);
  }, [scriptContainer]);
  return <div ref={scriptContainer}></div>;
};

export default CookieDeclaration;
