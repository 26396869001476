import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import App from './App/App';
import configureStore from './store/configureStore';
import { rootSaga } from './modules';
import './normalise.css';
import './global-styles/Typography.css';

const history = createBrowserHistory();
const store = configureStore(history);

store.runSaga(rootSaga);

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS, { debug: false });
  ReactGA.plugin.require('ec');
  ReactGA.pageview('/');
}

ReactDOM.render(
  <React.StrictMode>
    <App store={store} history={history} />
  </React.StrictMode>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./App/App', () => {
    ReactDOM.render(
      <App store={store} history={history} />,
      document.getElementById('root')
    );
  });
}
