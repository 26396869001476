export const HIDE_SPECIFIC_REQUIREMENTS =
  'line-up/specific-requirements/HIDE_SPECIFIC_REQUIREMENTS';
export const SHOW_SPECIFIC_REQUIREMENTS =
  'line-up/specific-requirements/SHOW_SPECIFIC_REQUIREMENTS';
export const ADD_SPECIFIC_REQUIREMENTS =
  'line-up/specific-requirements/ADD_SPECIFIC_REQUIREMENT';
export const REMOVE_SPECIFIC_REQUIREMENT_MESSAGE =
  'line-up/specific-requirements/REMOVE_SPECIFIC_REQUIREMENT_MESSAGE';
export const ADD_SPECIFIC_REQUIREMENT_MESSAGE =
  'line-up/specific-requirements/ADD_SPECIFIC_REQUIREMENT_MESSAGE';
export const CLEAR_SPECIFIC_REQUIREMENT_MESSAGE =
  'line-up/specific-requirements/CLEAR_SPECIFIC_REQUIREMENT_MESSAGE';
