import { takeEvery } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { submitForm } from '../shared/operations';

export const createOrder = submitForm.bind(
  null,
  actions.createOrder,
  api.createOrder
);

export const updateOrder = submitForm.bind(
  null,
  actions.updateOrder,
  api.createOrder // Using same API call as create to update
);

export function* watchCreateOrder() {
  yield takeEvery(actions.createOrder.TRIGGER, createOrder);
  yield takeEvery(actions.updateOrder.TRIGGER, updateOrder);
}
