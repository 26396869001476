import React from 'react';
import './ImageSlider.css';

export {
  default as ImageSliderSlider
} from './ImageSliderSlider/ImageSliderSlider';
export { default as ImageSliderItem } from './ImageSliderItem/ImageSliderItem';
export {
  default as ImageSliderCover
} from './ImageSliderCover/ImageSliderCover';

const ImageSlider = ({ children }) => {
  return <div className="slider-container">{children}</div>;
};

export default ImageSlider;
