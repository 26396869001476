import React from 'react';
import { Link, Element } from 'react-scroll';
import './DoctorWho.css';

import DWTFFooter from '../../components/DWTFFooter/DWTFFooter';
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from '../../components/MainContent/MainContent';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import FullBackgroundImage, {
  FullBackgroundImageCover,
  FullBackgroundImageCoverContentWrap,
  FullBackgroundImageCoverContent
} from '../../components/FullBackgroundImage/FullBackgroundImage';
import ScrollIndicator from '../../components/ScrollIndicator/ScrollIndicator';
import Icon from '../../components/Icon/Icon';
import ImageSlider, {
  ImageSliderSlider,
  ImageSliderItem
} from '../../components/ImageSlider/ImageSlider';

import DWbackground from '../../images/doctor-who/DoctorWho_bg_home3.jpg';
import DWbackgroundMobile from '../../images/doctor-who/DoctorWho_bg_mobile4.jpg';

import IELogo from '../../images/doctor-who/IE_Logo.png';
import TLVLogo from '../../images/doctor-who/TLV_logo.png';

import DWTFLogoLarge from '../../images/doctor-who/DoctorWho_logo_large.png';

import Slider1 from '../../images/dwtf-production-stills/slider1.jpg';
import Slider2 from '../../images/dwtf-production-stills/slider2.jpg';
import Slider3 from '../../images/dwtf-production-stills/slider3.jpg';
import Slider4 from '../../images/dwtf-production-stills/slider4.jpg';
import Slider5 from '../../images/dwtf-production-stills/slider5.jpg';
import Slider6 from '../../images/dwtf-production-stills/slider6.jpg';

const splashStyle = {
  backgroundImage: 'url(' + DWbackground + ')'
};

const DoctorWho = ({ match }) => {
  return (
    <div className='App App--DWTF'>
      <PageContentBlock nomargin>
        <div className='dw-main-artwork-desktop'>
          <FullBackgroundImage dw style={splashStyle}>
            <FullBackgroundImageCover>
              <FullBackgroundImageCoverContentWrap topmargin fadein>
                <FullBackgroundImageCoverContent dwlogo>
                  <img
                    src={DWTFLogoLarge}
                    alt='Doctor Who: Time Fracture'
                    className='full-image-background-cover__content-image'
                  />
                </FullBackgroundImageCoverContent>

                <ScrollIndicator fadein>
                  <Link
                    to='about'
                    smooth='easeInOutQuad'
                    offset={0}
                    duration={1000}
                    className='link gold-font'
                  >
                    <Icon name='chevron-down' nomargin small verticalanimated />
                  </Link>
                </ScrollIndicator>
              </FullBackgroundImageCoverContentWrap>
            </FullBackgroundImageCover>
          </FullBackgroundImage>
        </div>
        <div className='dw-main-artwork-mobile'>
          <div className='dw-main-artwork-mobile'>
            <img
              src={DWbackgroundMobile}
              className='dw-main-artwork-image'
              alt='Doctor Who: Time Fracture'
              title='Doctor Who: Time Fracture'
            />
          </div>
        </div>
      </PageContentBlock>

      <div className='aug-update-container'>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <Element name='update' id='update'>
              <TextBlock>
                <h3 className='gatsby-h3 aug-update-copy'>Update: June 2022</h3>
                <p className='aug-update-copy'>
                  Our journey through space and time came to an end sooner than
                  expected - but what a joyful journey this has been.
                </p>

                <p className='aug-update-copy'>
                  The final performance was on Sunday 5th June 2022.
                </p>

                <p className='aug-update-copy'>
                  Doctor Who: Time Fracture was created as a love letter to
                  Doctor Who fans and, despite facing extreme challenges over
                  the past two years, we are extremely proud that its vision and
                  ambition was so beautifully delivered.
                </p>

                <p className='aug-update-copy'>
                  Thank you for being a part of our wibbly wobbly, timey wimey
                  adventure. To keep in touch with future opportunities to
                  experience Doctor Who: Time Fracture sign up{' '}
                  <a
                    href='https://immersiveeverywhere.com/#newsletter'
                    className='gatsby-body-text-link'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    here
                  </a>
                  .
                </p>
              </TextBlock>
            </Element>
          </PageContentBlock>
        </ContentContainer>
      </div>

      <MainContent>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <Element name='about' id='about'>
              <div className='reviews-container'>
                <div className='review-main'>
                  <div className='review'>
                    <div className='review-stars'>★★★★</div>
                    <div className='review-quote'>
                      'A LAVISHLY DESIGNED, HIGH ENERGY ADVENTURE'
                    </div>
                    <div className='review-publication'>DAILY MAIL</div>
                  </div>
                </div>
                <div className='review-inline review-inline--narrow'>
                  <div className='review'>
                    <div className='review-quote'>'A RIP ROARING TIME'</div>
                    <div className='review-publication'>METRO</div>
                  </div>
                  <div className='review'>
                    <div className='review-quote'>'A VISCERAL THRILL'</div>
                    <div className='review-publication'>THE GUARDIAN</div>
                  </div>
                </div>
                <div className='review-main'>
                  <div className='review'>
                    <div className='review-stars'>★★★★</div>
                    <div className='review-quote'>
                      'EMOTIONAL & ENJOYABLE FUN'
                    </div>
                    <div className='review-publication'>THE TELEGRAPH</div>
                  </div>
                </div>
              </div>
              <TextBlock>
                <p>
                  Doctor Who: Time Fracture, a ground-breaking immersive
                  theatrical adventure, plunges you into the incredible universe
                  of Doctor Who.
                </p>

                <p>
                  The Doctor needs you! The Universe as we know it is at stake –
                  now is the time to step up and be the hero. For decades, in a
                  quiet corner of Mayfair, London, a dangerous rift in time and
                  space has been monitored by a group of loyal members of the
                  long-thought-disbanded Unified Intelligence Taskforce – or
                  UNIT for short. Until now they have managed to protect the
                  people of Earth from the threat the rift poses but, weakened
                  and beaten back as the Time Fracture grows out of control,
                  they’re now close to defeat. With 40 live actors and 17
                  different worlds to explore, take an epic journey across space
                  and time, travel to exciting new (and old) places, confront
                  menacing monsters and encounter ancient aliens – all while you
                  battle to save all of existence!
                </p>

                <p>
                  From producers Immersive Everywhere – the creative minds
                  behind London’s longest running and critically acclaimed
                  immersive theatre production, The Great Gatsby – and
                  officially licensed by BBC Studios, Doctor Who: Time Fracture
                  must end 4 September 2022 - book now to avoid disappointment!
                </p>
              </TextBlock>

              <div className='reviews-container'>
                <div className='review-inline review-inline-mobile-break'>
                  <div className='review'>
                    <div className='review-quote'>
                      'SPECTACULAR - PERFORMED WITH FLAIR BY A HARDWORKING CAST'
                    </div>
                    <div className='review-publication'>FINANCIAL TIMES</div>
                  </div>
                  <div className='review'>
                    <div className='review-quote'>
                      'RELENTLESSLY ENERGETIC & THRILLING'
                    </div>
                    <div className='review-publication'>DAILY EXPRESS</div>
                  </div>
                  <div className='review'>
                    <div className='review-quote'>
                      'STUFFED WITH GREAT EASTER EGGS, WINNING PERFORMANCES AND
                      FUN CHARACTERS'
                    </div>
                    <div className='review-publication'>RADIO TIMES</div>
                  </div>
                </div>
              </div>
            </Element>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <Element name='trailer' id='trailer'>
        <VideoPlayer
          gatsby
          src='https://www.youtube.com/embed/YM1xtERtHfk?rel=0&modestbranding=1&autohide=1&showinfo=0;'
        />
      </Element>

      <MainContent gatsby notoppadding>
        <PageContentBlock nomargin>
          <ImageSlider>
            <ImageSliderSlider>
              <ImageSliderItem src={Slider1} alt='Doctor Who: Time Fracture' />
              <ImageSliderItem src={Slider2} alt='Doctor Who: Time Fracture' />
              <ImageSliderItem src={Slider3} alt='Doctor Who: Time Fracture' />
              <ImageSliderItem src={Slider4} alt='Doctor Who: Time Fracture' />
              <ImageSliderItem src={Slider5} alt='Doctor Who: Time Fracture' />
              <ImageSliderItem src={Slider6} alt='Doctor Who: Time Fracture' />
            </ImageSliderSlider>
          </ImageSlider>
        </PageContentBlock>
      </MainContent>

      <MainContent gatsby notoppadding>
        <ContentContainer shadow='true' gatsby='true'>
          <TextBlock>
            <h4 className='gatsby-h4'>PRESENTED BY</h4>
            <div className='credit-image-wrapper'>
              <img
                src={IELogo}
                className='credit-image'
                alt='Immersive Everywhere'
              />
            </div>
            <h4 className='gatsby-h4'>AND PART OF</h4>
            <div className='credit-image-wrapper'>
              <img
                src={TLVLogo}
                className='credit-image'
                alt='Doctor Who Time Lord Victorious'
              />
            </div>
            <p className='credits'>
              BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
              logos) are trade marks of the British Broadcasting Corporation and
              are used under licence. BBC logo © BBC 1996. Doctor Who logo © BBC
              2018. Dalek image © BBC/Terry Nation 1963. Cyberman image ©
              BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
            </p>
          </TextBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWho;
