import React from "react";
import classNames from "classnames";
import Spinner from "react-spinkit";
import "./Button.css";

const Button = (
  {
    href,
    type = "button",
    children,
    onClick,
    disabled,
    className,
    loading,
    gatsby,
    second,
    secondary,
    primary,
    short,
    large,
    event,
    bottombar,
    full,
    mobilefull,
    remove,
    linkstyle,
    booking,
    bookingsecondary,
    newcard,
    social,
    facebook,
    twitter,
    margintop,
    table,
    small
  },
  props
) => {
  const btnClass = classNames("button", className, {
    "button--gatsby": gatsby,
    "button--second": second,
    "button--primary": primary,
    "button--secondary": secondary,
    "button--short": short,
    "button--large": large,
    "button--event": event,
    "button--bottom-bar": bottombar,
    "button--full": full,
    "button--mobile-full": mobilefull,
    "button--delete": remove,
    "button-link-style": linkstyle,
    "button--booking": booking,
    "button--booking-secondary": bookingsecondary,
    "button--add-new-card": newcard,
    "button--social": social,
    "button--share-facebook": facebook,
    "button--share-twitter": twitter,
    "button--margin-top": margintop,
    "button--table": table,
    "button--small": small
  });
  const Element = href ? "a" : "button";
  const role = Element === "a" ? "button" : null;
  return (
    <Element
      className={btnClass}
      href={href}
      onClick={onClick}
      disabled={disabled || loading}
      role={role}
      type={type}
      {...props}
    >
      {loading ? (
        <Spinner
          name="pulse"
          color="#333"
          fade="none"
          className="button-spinner"
        />
      ) : (
        children
      )}
    </Element>
  );
};

export default Button;
