import { createAction } from 'redux-actions';
import * as types from './types';

export const loadTickets = createAction(types.LOAD, performanceId => ({
  performanceId
}));

export const tickets = {
  request: createAction(types.TICKETS.REQUEST),
  success: createAction(types.TICKETS.SUCCESS),
  failure: createAction(types.TICKETS.FAILURE)
};
