import React from 'react';
import classNames from 'classnames';
import './FullBackgroundImageCoverContent.css';

const FullBackgroundImageCoverContent = ({ children, dwlogo, className }) => {
  const FullBackgroundImageCoverContentClass = classNames(
    'full-image-background-cover__content',
    className,
    {
      'full-image-background-cover__content-dw-logo': dwlogo
    }
  );
  return <div className={FullBackgroundImageCoverContentClass}>{children}</div>;
};

export default FullBackgroundImageCoverContent;
