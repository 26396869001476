import { take, select, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';
import { createOrder } from '../order/actions';
import { getReservationReference } from '../reservation/selectors';
import { fetchEntity, createEntity, deleteEntity } from '../shared/operations';

export function* watchLoadCards() {
  yield takeEvery(actions.fetchCards.TRIGGER, fetchCards);
}

export const fetchCards = fetchEntity.bind(
  null,
  actions.fetchCards,
  api.fetchCards
);

export function* watchCreateCard() {
  yield takeLatest(actions.createCard.TRIGGER, createCard);
  while (true) {
    yield take(actions.createCard.SUCCESS);
    const reservationId = yield select(getReservationReference);
    yield put(createOrder({ id: reservationId }));
  }
}

export const createCard = createEntity.bind(
  null,
  actions.createCard,
  api.createCard
);

export function* watchDeleteCard() {
  yield takeEvery(actions.deleteCard.TRIGGER, deleteCard);
}

export const deleteCard = deleteEntity.bind(
  null,
  actions.deleteCard,
  api.deleteCard
);
