import React from 'react';
import classNames from 'classnames';
import './VideoPlayer.css';

const VideoPlayer = ({ className, gatsby, src }) => {
  const VideoPlayerClass = classNames('video-player', className, {
    'video-player--gatsby': gatsby
  });

  return (
    <div className={VideoPlayerClass}>
      <iframe
        className="video-player__iframe"
        width="560"
        height="315"
        src={src}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="youtube"
        rel="0"
        modestbranding="1"
        autohide="1"
        showinfo="0"
      />
    </div>
  );
};

export default VideoPlayer;
