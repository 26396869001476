import React from "react";
import classNames from "classnames";
import "./PageContentBlock.css";

const PageContentBlock = ({
  children,
  className,
  nomargin,
  topmargin,
  borders,
  multiple
}) => {
  const PageContentBlockClass = classNames("page-content-block", className, {
    "page-content-block--no-margin": nomargin,
    "page-content-block--top-margin": topmargin,
    "page-content-block--multiple": multiple
  });

  return <div className={PageContentBlockClass}>{children}</div>;
};

export default PageContentBlock;
