import { ticketSelectors } from '../tickets/tickets';
import { productSelectors } from '../products/products';
import { discountSelectors } from '../discount/discount';
const getQuantity = (state, id) => state.quantityById[id] || 0;
const getAddedIds = state => Object.keys(state.quantityById);

export const getProductQuantity = (state, id) =>
  getQuantity(state.basket.products, id);
export const getTicketQuantity = (state, id) =>
  getQuantity(state.basket.tickets, id);

export const getBasketTickets = state =>
  getAddedIds(state.basket.tickets).map(id => ({
    ...ticketSelectors.getTicket(state, id),
    quantity: getQuantity(state.basket.tickets, id)
  }));

export const getBasketProducts = state =>
  getAddedIds(state.basket.products).map(id => ({
    ...productSelectors.getProduct(state, id),
    quantity: getQuantity(state.basket.products, id)
  }));

export const getBasketItems = state => {
  return {
    tickets: getBasketTickets(state),
    products: getBasketProducts(state)
  };
};

export const getItemCount = state =>
  getAddedIds(state)
    .map(id => getQuantity(state, id))
    .reduce((a, b) => a + b, 0);

export const getBasketItemCount = state =>
  getItemCount(state.basket.tickets) + getItemCount(state.basket.products);

export const getBasketCurrency = state =>
  getAddedIds(state.basket.tickets)[0]
    ? ticketSelectors.getTicket(state, getAddedIds(state.basket.tickets)[0])
        .currency
    : '';

export const getBasket = state => {
  return {
    basket: {
      tickets: getAddedIds(state.basket.tickets).map(id => ({
        ticketId: parseInt(id, 10),
        discountCode: discountSelectors.getCurrentDiscountCode(state),
        quantity: getQuantity(state.basket.tickets, id)
      })),
      products: getAddedIds(state.basket.products).map(id => ({
        productId: parseInt(id, 10),
        quantity: getQuantity(state.basket.products, id)
      }))
    }
  };
};

export const getTicketFaceValueTotal = state => {
  return getAddedIds(state.basket.tickets).reduce(
    (total, id) =>
      total +
      ticketSelectors.getTicket(state, id).totalExFees *
        getQuantity(state.basket.tickets, id),
    0
  );
};

export const getBookingFeeTotal = state => {
  return getAddedIds(state.basket.tickets).reduce(
    (total, id) =>
      total +
      ticketSelectors.getTicket(state, id).feeTotal *
        getQuantity(state.basket.tickets, id),
    0
  );
};

export const getBasketTicketTotal = state => {
  return getAddedIds(state.basket.tickets).reduce(
    (total, id) =>
      total +
      ticketSelectors.getTicket(state, id).total *
        getQuantity(state.basket.tickets, id),
    0
  );
};

export const getBasketProductTotal = state => {
  return getAddedIds(state.basket.products).reduce(
    (total, id) =>
      total +
      productSelectors.getProduct(state, id).total *
        getQuantity(state.basket.products, id),
    0
  );
};

export const getFaceValueTotal = state =>
  getTicketFaceValueTotal(state) + getBasketProductTotal(state);
export const getBasketTotal = state =>
  getBasketTicketTotal(state) + getBasketProductTotal(state);
