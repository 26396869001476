import React from 'react';
import Slider from 'react-slick';
import './ImageSliderSlider.css';

const ImageSliderSlider = ({ children }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true
  };

  return (
    <Slider {...settings} className="slider-slider">
      {children}
    </Slider>
  );
};

export default ImageSliderSlider;
