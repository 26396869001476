import { combineReducers } from 'redux';
import * as types from './types';
import * as actions from './actions';

const byId = (state = [], action) => {
  switch (action.type) {
    case actions.fetchCards.TRIGGER:
      return [];
    case actions.fetchCards.SUCCESS:
      return [...action.payload.response.result];
    case actions.fetchCards.FAILURE:
      return [];
    case actions.fetchCards.FULFILL:
      return [...state];
    case actions.deleteCard.SUCCESS:
      return state.filter(cardId => cardId !== action.payload.cardId);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchCards.TRIGGER:
      return true;
    case actions.fetchCards.SUCCESS:
    case actions.fetchCards.FAILURE:
    case actions.fetchCards.FULFILL:
      return false;
    default:
      return state;
  }
};

const showCardList = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_CARD_LIST:
      return true;
    case types.HIDE_CARD_LIST:
      return false;
    default:
      return state;
  }
};

const showAddCard = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_ADD_CARD:
      return true;
    case types.HIDE_ADD_CARD:
      return false;
    case actions.fetchCards.SUCCESS:
      return action.payload.response.result.length ? false : true;
    default:
      return state;
  }
};

const cards = combineReducers({
  byId,
  loading,
  showCardList,
  showAddCard
});

export default cards;
