import moment from 'moment';
import { entitySelectors } from '../entities/entities';

export const getCurrentReservation = state =>
  entitySelectors.getEntity(
    state.entities.reservation,
    state.reservation.sessionId
  )
    ? entitySelectors.getEntity(
        state.entities.reservation,
        state.reservation.sessionId
      )
    : {};
export const getReservationReference = state =>
  getCurrentReservation(state).reference;
export const getSessionId = state => state.reservation.sessionId;
export const getHasValidReservation = state =>
  state.reservation.validReservation;
export const getExpiryDate = state =>
  state.reservation.sessionId
    ? moment.utc(getCurrentReservation(state).expireAt).toDate()
    : null;

export const getDiscountTotal = state =>
  getCurrentReservation(state)
    ? getCurrentReservation(state).discount.totalTicketDiscount +
      getCurrentReservation(state).discount.totalFeeDiscount
    : 0;

export const getFaceValueTotal = state =>
  getTotal(state) - getBookingFeeTotal(state);

export const getBookingFeeTotal = state =>
  getCurrentReservation(state) ? getCurrentReservation(state).feeTotal : 0;

export const getTotal = state =>
  getCurrentReservation(state) ? getCurrentReservation(state).total : 0;

export const getTotalPayable = state =>
  getCurrentReservation(state)
    ? getCurrentReservation(state).total - getDiscountTotal(state)
    : 0;

export const getCurrency = state => 'GBP';
export const getIsCreating = state => state.reservation.creating;
export const getReservationPerformanceId = state =>
  getTicketReservationItems(state)[0]
    ? getTicketReservationItems(state)[0].performance.id
    : null;

const getTicketReservationItems = state =>
  getCurrentReservation(state).ticketReservations
    ? getCurrentReservation(state).ticketReservations
    : [];
const getProductReservationItems = state =>
  getCurrentReservation(state).productReservations
    ? getCurrentReservation(state).productReservations
    : [];

export const getTickets = state =>
  getTicketReservationItems(state).reduce(
    (result, { event, performance, ticket, quantity }) => {
      result[event.id] = {
        ...result[event.id],
        ...event,
        performances: {
          ...(result[event.id] ? result[event.id]['performances'] : {}),
          [performance.id]: {
            ...performance,
            tickets: [
              ...(result[event.id]
                ? result[event.id]['performances'][performance.id]
                  ? result[event.id]['performances'][performance.id]['tickets']
                  : []
                : []),
              {
                ...ticket,
                quantity: quantity
              }
            ]
          }
        }
      };
      return result;
    },
    {}
  );

export const getProducts = state =>
  getProductReservationItems(state).reduce((result, { product, quantity }) => {
    result[product.id] = {
      ...result[product.id],
      ...product,
      quantity: quantity
    };
    return result;
  }, {});

export const getStripeTotal = state =>
  parseInt(getTotalPayable(state) * 100, 10);
