import { takeEvery } from 'redux-saga/effects';

import * as api from '../../services/api';
import * as actions from './actions';
import { submitForm } from '../shared/operations';

export const addSpecificRequirement = submitForm.bind(
  null,
  actions.addSpecificRequirement,
  api.addDiscountCode
);

export function* watchAddDiscountCode() {
  yield takeEvery(
    actions.addSpecificRequirement.TRIGGER,
    addSpecificRequirement
  );
}
