import { takeLatest, call, put, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import store from 'store';
import * as api from '../../services/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

function* updateSession() {
  const token = yield select(selectors.getToken);
  if (token) {
    store.set(process.env.REACT_APP_AUTH_TOKEN_NAME, token);
  }
}

const logOut = action => {
  store.remove(process.env.REACT_APP_AUTH_TOKEN_NAME);
};

const updateAffiliate = action => {
  if (action.payload) {
    store.set(process.env.REACT_APP_AFFILIATE_TOKEN_NAME, action.payload);
  }
};
export function* loadAffiliate() {
  const affiliate = store.get(process.env.REACT_APP_AFFILIATE_TOKEN_NAME);
  if (affiliate) {
    yield put(actions.trackAffiliate(affiliate));
  }
}
export function* watchLogOut() {
  yield takeLatest(types.LOGOUT, logOut);
}

export function* sendForgotPassword(action) {
  yield put(actions.sendForgotPassword.request());
  const { response, error } = yield call(
    api.sendForgotPassword,
    action.payload.values
  );
  if (response) yield put(actions.sendForgotPassword.success());
  else
    yield put(
      actions.sendForgotPassword.failure(new SubmissionError(error.errors))
    );
  yield put(actions.sendForgotPassword.fulfill());
}

export function* register(action) {
  yield put(actions.register.request());
  const { response, error } = yield call(api.register, { ...action.payload });
  if (response)
    yield put(actions.register.success({ response, ...action.payload }));
  else yield put(actions.register.failure(new SubmissionError(error.errors)));
  yield put(actions.register.fulfill());
}

export function* login(action) {
  yield put(actions.login.request());
  const { response, error } = yield call(api.authorize, action.payload.values);
  if (response) {
    yield put(actions.login.success({ response }));
  } else yield put(actions.login.failure(new SubmissionError(error.errors)));
  yield put(actions.login.fulfill());
}

export function* fetchCustomer(action) {
  const authToken = store.get(process.env.REACT_APP_AUTH_TOKEN_NAME);
  if (authToken) {
    yield put(actions.fetchCustomer.request());
    const { response } = yield call(api.fetchCustomer, authToken);
    if (response) {
      yield call(updateSession, response.accessToken);
      yield put(actions.fetchCustomer.success({ response }));
    } else yield put(actions.fetchCustomer.failure());
    yield put(actions.fetchCustomer.fulfill());
  }
}

export function* watchRegister() {
  yield takeLatest(actions.register.TRIGGER, register);
}

export function* watchLogin() {
  yield takeLatest(actions.login.TRIGGER, login);
}

export function* watchLoadCustomer() {
  yield takeLatest(actions.fetchCustomer.TRIGGER, fetchCustomer);
}

export function* watchSendPasswordReset() {
  yield takeLatest(actions.sendForgotPassword.TRIGGER, sendForgotPassword);
}

export function* watchTrackAffiliate() {
  yield takeLatest(types.TRACK_AFFILIATE, updateAffiliate);
}

export function* watchLoadAffiliate() {
  yield takeLatest(types.LOAD_AFFILIATE, loadAffiliate);
}
export function* watchLoadCurrentUser() {
  yield takeLatest(actions.fetchCustomer.SUCCESS, updateSession);
  yield takeLatest(actions.login.SUCCESS, updateSession);
}
