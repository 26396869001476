import { takeEvery } from 'redux-saga/effects';

import * as api from '../../services/api';
import * as actions from './actions';
import { submitForm } from '../shared/operations';

export const addDiscountCode = submitForm.bind(
  null,
  actions.addDiscountCode,
  api.addDiscountCode
);

export function* watchAddDiscountCode() {
  yield takeEvery(actions.addDiscountCode.TRIGGER, addDiscountCode);
}
