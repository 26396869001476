import React from 'react';
import './ContentContainer.css';
import classNames from 'classnames';

// export { default as Title } from './Title/Title';
// export { default as SubTitle } from './SubTitle/SubTitle';
// export { default as HeaderActions } from './HeaderActions/HeaderActions';
// export { default as ContentSummary } from './ContentSummary/ContentSummary';
// export { default as ContentColumn } from './ContentColumn/ContentColumn';
// export {
//   default as ContentContainer
// } from './ContentContainer/ContentContainer';

const ContentContainer = ({
  children,
  className,
  shadow,
  gatsby,
  gatsbyBooking,
  wolf,
  stratton,
  black
}) => {
  const ContainerClass = classNames('content-container', className, {
    'content-container--shadow': shadow,
    'content-container--gatsby': gatsby,
    'content-container--gatsby-booking': gatsbyBooking,
    'content-container--wolf': wolf,
    'content-container--stratton': stratton,
    'content-container--black': black
  });
  return <div className={ContainerClass}>{children}</div>;
};

export default ContentContainer;
