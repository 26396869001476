import { combineReducers } from 'redux';
import * as actions from './actions';
import * as types from './types';

const id = (state = null, action) => {
  switch (action.type) {
    case actions.createOrder.TRIGGER:
    case actions.createOrder.FAILURE:
    case actions.updateOrder.FAILURE:
    case types.CLEAR_ORDER:
      return null;
    case actions.createOrder.SUCCESS:
      return action.payload.response.result;
    default:
      return state;
  }
};

const creating = (state = false, action) => {
  switch (action.type) {
    case actions.createOrder.TRIGGER:
    case actions.updateOrder.TRIGGER:
      return true;
    case actions.createOrder.FAILURE:
    case actions.updateOrder.FAILURE:
    case types.COMPLETE_ORDER:
    case types.CLEAR_ORDER:
      return false;
    default:
      return state;
  }
};

const order = combineReducers({
  id,
  creating
});

export default order;
