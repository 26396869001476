import React from "react";
import classNames from "classnames";
import "./MainContent.css";

export {
  default as ContentContainer
} from "./ContentContainer/ContentContainer";
export {
  default as PageContentBlock
} from "./PageContentBlock/PageContentBlock";
export { default as TextBlock } from "./TextBlock/TextBlock";
export { default as FullImageBlock } from "./FullImageBlock/FullImageBlock";

const MainContent = ({
  children,
  className,
  event,
  gatsby,
  regular,
  mainContentBackgroundColor,
  notoppadding
}) => {
  const MainContentClass = classNames("main-content", className, {
    "main-content--event": event,
    "main-content--gatsby": gatsby,
    "main-content--regular": regular,
    "main-content--notoppadding": notoppadding
  });
  const styles = {
    backgroundColor: `${mainContentBackgroundColor}`
  };
  return (
    <div className={MainContentClass} style={styles}>
      {children}
    </div>
  );
};

export default MainContent;
