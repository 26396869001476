import { combineReducers } from 'redux';
import * as types from './types';
import { reservationTypes } from '../reservation/reservation';

const updateBasket = (state, actionType, itemId, quantity = 1) => {
  switch (actionType) {
    case types.ADD_TICKET:
    case types.ADD_PRODUCT:
      return {
        ...state,
        [itemId]: (state[itemId] || 0) + quantity
      };
    case types.REMOVE_TICKET:
    case types.REMOVE_PRODUCT:
      const qty = (state[itemId] || 0) - quantity;
      const copy = { ...state };
      if (qty > 0) copy[itemId] = qty;
      else delete copy[itemId];
      return copy;
    default:
      return state;
  }
};
const defaultState = {
  quantityById: {}
};

const tickets = (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_TICKET:
    case types.REMOVE_TICKET:
      return {
        quantityById: updateBasket(
          state.quantityById,
          action.type,
          action.payload.ticketId,
          action.payload.quantity
        )
      };
    case reservationTypes.EXPIRE_RESERVATION:
      return defaultState;
    default:
      return state;
  }
};

const products = (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_PRODUCT:
    case types.REMOVE_PRODUCT:
      return {
        quantityById: updateBasket(
          state.quantityById,
          action.type,
          action.payload.productId
        )
      };
    case reservationTypes.EXPIRE_RESERVATION:
      return defaultState;
    default:
      return state;
  }
};

export default combineReducers({
  tickets,
  products
});
