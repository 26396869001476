import { entitySelectors } from '../entities/entities';

export const getOrderId = state => state.order.id;
export const getOrder = state =>
  getOrderId(state)
    ? entitySelectors.getEntity(state.entities.order, state.order.id)
    : null;
export const getIsOrderComplete = state => {
  const order = getOrder(state);
  return order ? order.reference : null;
};
export const getOrderPaymentIntentClientSecret = state => {
  const order = getOrder(state);
  return order ? order.paymentIntentClientSecret : null;
};
export const getOrderRequiresAction = state => {
  const order = getOrder(state);
  return order ? order.paymentIntentClientSecret : null;
};

export const getIsCreating = state => state.order.creating;
