import { combineReducers } from 'redux';
import * as types from './types';
import * as actions from './actions';

const currentUser = (state = {}, action) => {
  switch (action.type) {
    case actions.login.SUCCESS:
    case actions.register.SUCCESS:
    case actions.fetchCustomer.SUCCESS: {
      return {
        ...state,
        customerId: action.payload.response.result
      };
    }
    case actions.login.FAILURE: {
      return { ...state, error: action.payload.error };
    }
    case types.LOGOUT: {
      return {
        error: null,
        customerId: null
      };
    }
    default:
      return state;
  }
};

const forgotPassword = (
  state = {
    isSent: false,
    sending: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case actions.sendForgotPassword.TRIGGER:
      return {
        ...state,
        isSent: false,
        sending: true
      };
    case actions.sendForgotPassword.SUCCESS:
      return {
        ...state,
        isSent: true
      };
    default:
      return state;
  }
};

const showLoginForm = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_LOGIN_FORM:
      return true;
    case types.HIDE_LOGIN_FORM:
    case actions.login.SUCCESS:
      return false;
    default:
      return state;
  }
};

const showForgotPassword = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_FORGOT_PASSWORD_FORM:
      return true;
    case types.HIDE_FORGOT_PASSWORD_FORM:
    case types.SHOW_LOGIN_FORM:
      return false;
    default:
      return state;
  }
};

const affiliate = (state = null, action) => {
  switch (action.type) {
    case types.TRACK_AFFILIATE:
      return action.payload;
    default:
      return state;
  }
};

const auth = combineReducers({
  currentUser,
  forgotPassword,
  showLoginForm,
  showForgotPassword,
  affiliate
});

export default auth;
