import { createAction } from 'redux-actions';
import {
  createRoutine,
  bindRoutineToReduxForm,
  promisifyRoutine
} from 'redux-saga-routines';
import * as types from './types';

export const logOut = createAction(types.LOGOUT);
export const sendForgotPassword = createRoutine(types.SEND_PASSWORD_RESET);
export const register = createRoutine(types.REGISTER);
export const login = createRoutine(types.LOGIN);
export const fetchCustomer = createRoutine(types.LOAD_LOGGED_IN_CUSTOMER);

export const submitRegisterForm = promisifyRoutine(register);
export const submitLoginForm = bindRoutineToReduxForm(login);
export const submitForgotPassword = bindRoutineToReduxForm(sendForgotPassword);

export const showLoginForm = createAction(types.SHOW_LOGIN_FORM);
export const hideLoginForm = createAction(types.HIDE_LOGIN_FORM);

export const showForgotPasswordForm = createAction(
  types.SHOW_FORGOT_PASSWORD_FORM
);
export const hideForgotPasswordForm = createAction(
  types.HIDE_FORGOT_PASSWORD_FORM
);

export const trackAffiliate = createAction(types.TRACK_AFFILIATE);
export const loadAffiliate = createAction(types.LOAD_AFFILIATE);
