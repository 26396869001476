import React from 'react';
import './ImageSliderItem.css';

const ImageSliderItem = ({ children, src, alt }) => {
  return (
    <div className="slider-item">
      <img src={src} alt={alt} className="slider-item__image" />
    </div>
  );
};

export default ImageSliderItem;
