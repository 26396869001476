import { createAction } from 'redux-actions';
import * as types from './types';

export const addTicketToBasket = createAction(
  types.ADD_TICKET,
  (ticketId, quantity) => ({
    ticketId,
    quantity
  })
);
export const removeTicketFromBasket = createAction(
  types.REMOVE_TICKET,
  (ticketId, quantity) => ({ ticketId, quantity })
);
export const addProductToBasket = createAction(
  types.ADD_PRODUCT,
  productId => ({
    productId
  })
);
export const removeProductFromBasket = createAction(
  types.REMOVE_PRODUCT,
  productId => ({ productId })
);
