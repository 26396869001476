import { takeEvery, takeLatest } from 'redux-saga/effects';

import * as api from '../../services/api';
import * as actions from './actions';
import { createEntity } from '../shared/operations';

import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

export const createReservation = createEntity.bind(
  null,
  actions.createReservation,
  api.createReservation
);

export function* redirectToConfirm(action) {
  yield put(push(action.payload.successUrl));
}
export function* watchCreateReservationSuccess() {
  yield takeLatest(actions.createReservation.SUCCESS, redirectToConfirm);
}
export function* watchCreateReservation() {
  yield takeEvery(actions.createReservation.TRIGGER, createReservation);
}
