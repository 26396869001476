import { entitySelectors } from '../entities/entities';

export const getTicket = (state, ticketId) =>
  entitySelectors.getEntity(state.entities.ticket, ticketId);

export const getTicketIdsForPerformance = (state, performanceId) =>
  state.tickets.byPerformanceId[performanceId]
    ? state.tickets.byPerformanceId[performanceId].ids
    : [];
export const getTickets = (state, performanceId) =>
  getTicketIdsForPerformance(state, performanceId).map(id =>
    getTicket(state, id)
  );

export const getLoading = (state, performanceId) =>
  state.tickets.byPerformanceId[performanceId]
    ? state.tickets.byPerformanceId[performanceId].isLoading
    : false;
