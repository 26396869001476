import merge from 'lodash/merge';

const entities = (state = {}, action) => {
  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.entities
  ) {
    return merge({}, state, action.payload.response.entities);
  }
  return state;
};

export default entities;
