import ExtendableError from 'es6-error';

class ApiError extends ExtendableError {
  constructor(errors) {
    super('API Error');
    this.errors = errors;
  }
}

class NetworkError extends ExtendableError {
  constructor(
    message = 'Unable connect to the server, please check your network connection and try again'
  ) {
    super(message);
    this.errors = { _error: message };
  }
}

export { ApiError, NetworkError };
