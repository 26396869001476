import { takeEvery } from 'redux-saga/effects';

import * as api from '../../services/api';
import * as types from './types';
import * as actions from './actions';
import { fetchEntity } from '../shared/operations';

export const fetchTickets = fetchEntity.bind(
  null,
  actions.tickets,
  api.fetchTickets
);

export function* watchLoadTickets() {
  yield takeEvery(types.LOAD, fetchTickets);
}
