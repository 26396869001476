import { combineReducers } from 'redux';
import * as types from './types';
import * as actions from './actions';

const showDiscountForm = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_DISCOUNT_FORM:
      return true;
    case types.HIDE_DISCOUNT_FORM:
    case actions.addDiscountCode.SUCCESS:
      return false;
    default:
      return state;
  }
};
const currentDiscountCode = (state = null, action) => {
  switch (action.type) {
    case actions.addDiscountCode.SUCCESS:
      return action.payload.discountCode;
    case actions.addDiscountCode.FAILURE:
    case actions.addDiscountCode.REQUEST:
      return null;
    default:
      return state;
  }
};
const discounts = combineReducers({
  showDiscountForm,
  currentDiscountCode
});

export default discounts;
