import { createAction } from 'redux-actions';
import * as types from './types';
import { createRoutine } from 'redux-saga-routines';

export const fetchCards = createRoutine('FETCH_CARDS');
export const createCard = createRoutine('CREATE_CARD');
export const deleteCard = createRoutine('DELETE_CARD');

export const showCardList = createAction(types.SHOW_CARD_LIST);
export const hideCardList = createAction(types.HIDE_CARD_LIST);
export const showAddCard = createAction(types.SHOW_ADD_CARD);
export const hideAddCard = createAction(types.HIDE_ADD_CARD);
