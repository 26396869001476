import reducer from './reducers';
import * as discountSelectors from './selectors';
import * as discountActions from './actions';
import * as discountOperations from './operations';
import * as discountTypes from './types';

export {
  discountSelectors,
  discountActions,
  discountOperations,
  discountTypes
};

export default reducer;
