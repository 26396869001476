import ReactGA from 'react-ga';
import { orderActions, orderSelectors } from '../modules/order/order';
import { reservationActions } from '../modules/reservation/reservation';
import { basketSelectors, basketTypes } from '../modules/basket/basket';
import { ticketSelectors } from '../modules/tickets/tickets';
import { LOCATION_CHANGE } from 'connected-react-router';

const options = {};
const trackPage = page => {
  ReactGA.set({
    page,
    ...options
  });
  ReactGA.pageview(page);
};

const addItem = (id, name, price, quantity, category = 'ticket') => {
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: id,
    name: name,
    price: price,
    quantity: quantity,
    category: category
  });
};

const removeItem = (id, name, price, quantity, category = 'ticket') => {
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: id,
    name: name,
    price: price,
    quantity: quantity,
    category: category
  });
};

const addBasket = order => {
  if (order.ticketPurchases) {
    order.ticketPurchases.map(item =>
      addItem(
        item.ticket.id,
        item.ticket.description,
        item.ticket.total,
        item.quantity
      )
    );
  }
};

const trackAddTicketToBasket = (state, item) => {
  const ticket = ticketSelectors.getTicket(state, item.ticketId);
  addItem(ticket.id, ticket.description, ticket.total, item.quantity);
  ReactGA.plugin.execute('ec', 'setAction', 'add');
  ReactGA.event({
    category: 'ticket table',
    action: 'click',
    label: 'addToBasket'
  });
};

const trackRemoveTicketFromBasket = (state, item) => {
  const ticket = ticketSelectors.getTicket(state, item.ticketId);
  removeItem(ticket.id, ticket.description, ticket.total, item.quantity);
  ReactGA.plugin.execute('ec', 'setAction', 'remove');
  ReactGA.event({
    category: 'ticket table',
    action: 'click',
    label: 'removeFromBasket'
  });
};

const startCheckout = (state, step = 1) => {
  const tickets = basketSelectors.getBasketTickets(state);
  tickets.forEach(ticket =>
    addItem(ticket.id, ticket.description, ticket.total, ticket.quantity)
  );
  ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
    step: step
  });
  if (step === 1) {
    ReactGA.event({
      category: 'ticket table',
      action: 'click',
      label: 'continue'
    });
  }
};

const trackPurchase = (state, response) => {
  const order = orderSelectors.getOrder(state);

  if (order) {
    addBasket(order);

    ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
      id: order.reference,
      revenue: order.total,
      tax: order.tax
    });
    ReactGA.event({
      category: 'confirm',
      action: 'click',
      label: 'purchase'
    });
  }
};

const analyticsMiddleware = store => next => action => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const nextPage = `${action.payload.location.pathname}${
        action.payload.location.search
      }`;
      if (nextPage.indexOf('confirm') >= 0) {
        startCheckout(store.getState(), 2);
      }
      trackPage(nextPage);
      break;
    }
    case basketTypes.ADD_TICKET:
      trackAddTicketToBasket(store.getState(), action.payload);
      break;
    case basketTypes.REMOVE_TICKET:
      trackRemoveTicketFromBasket(store.getState(), action.payload);
      break;
    case reservationActions.createReservation.SUCCESS:
      startCheckout(store.getState());
      break;
    case orderActions.createOrder.FULFILL:
      trackPurchase(store.getState(), action.response);
      break;
    default:
      break;
  }
  return next(action);
};

export default analyticsMiddleware;
