import { call, put, fork, takeEvery } from 'redux-saga/effects';
import * as api from '../../services/api';
import * as actions from './actions';

import { fetchEntity } from '../shared/operations';

export function* fetchPerformances(eventSlug, page) {
  yield put(actions.fetchPerformances.request({ eventSlug, page }));
  const { response, error } = yield call(
    api.fetchPerformances,
    eventSlug,
    page
  );
  if (response) {
    yield put(actions.fetchPerformances.success({ response }));
    return response;
  } else yield put(actions.fetchPerformances.failure({ error }));
}

function* fetchMultiEventPerformances(action) {
  const eventSlug = action.payload;
  if (eventSlug === 'the-great-gatsby') {
    yield fork(fetchPagedPerformances, 'the-great-gatsby-45');
  } else {
    yield fork(fetchPagedPerformances, eventSlug);
  }
}
function* fetchPagedPerformances(eventSlug) {
  let page = 1;
  const response = yield call(fetchPerformances, eventSlug, page);
  if (response) {
    const { pagination } = response;
    while (page < pagination.totalPages) {
      page++;
      yield fork(fetchPerformances, eventSlug, page);
    }
  }
}

function* fetchAllPerformances(action) {
  yield call(fetchMultiEventPerformances, action);
  yield put(actions.fetchPerformances.fulfill());
}
export function* watchLoadPerformances() {
  yield takeEvery(actions.fetchPerformances.TRIGGER, fetchAllPerformances);
}

export function* watchLoadPerformance() {
  yield takeEvery(actions.fetchPerformance.TRIGGER, fetchPerformance);
}
export const fetchPerformance = fetchEntity.bind(
  null,
  actions.fetchPerformance,
  api.fetchPerformance
);
