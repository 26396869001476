import { combineReducers } from 'redux';
import * as types from './types';

const updateTickets = (
  state = {
    isLoading: false,
    ids: []
  },
  action
) => {
  switch (action.type) {
    case types.TICKETS.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case types.TICKETS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ids: action.payload.response.result
      };
    case types.TICKETS.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

const byPerformanceId = (state = {}, action) => {
  switch (action.type) {
    case types.TICKETS.REQUEST:
    case types.TICKETS.SUCCESS:
    case types.TICKETS.FAILURE:
      const performanceId = action.payload.performanceId;
      return {
        ...state,
        [performanceId]: updateTickets(state[performanceId], action)
      };
    default:
      return state;
  }
};

const tickets = combineReducers({
  byPerformanceId
});

export default tickets;
