import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { library } from '@fortawesome/fontawesome-svg-core';
import DoctorWhoHome from '../containers/DoctorWho/DoctorWhoHome';
import DoctorWhoCookies from '../containers/DoctorWho/DoctorWhoCookies';

import './App.css';

// Icons
import {
  faMinus,
  faPlus,
  faLock,
  faCalendar,
  faClock,
  faMapMarker,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import {
  faCcVisa,
  faFacebook,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
library.add(
  faMinus,
  faPlus,
  faLock,
  faCcVisa,
  faCalendar,
  faClock,
  faFacebook,
  faTwitter,
  faMapMarker,
  faChevronDown
);

const App = ({ store, history }) => (
  <Fragment>
    <Provider store={store}>
      <IntlProvider locale='en-GB'>
        <ConnectedRouter history={history}>
          <div className='router'>
            <Switch>
              <Route exact path='/cookie-policy' component={DoctorWhoCookies} />
              <Route component={DoctorWhoHome} />
            </Switch>
          </div>
        </ConnectedRouter>
      </IntlProvider>
    </Provider>

    <Helmet>
      <title>Doctor Who: Time Fracture</title>
      <meta
        name='description'
        content='Doctor Who: Time Fracture, a ground-breaking Immersive Theatrical Adventure, plunges you into the incredible Universe of Doctor Who.'
      />
      <meta
        name='facebook-domain-verification'
        content='48dyarg9gi8by696hvgnrdzw5qukj4'
      />
      <script
        id='Cookiebot'
        src='https://consent.cookiebot.com/uc.js'
        data-cbid='760529c1-ac58-4b5e-acad-2ff32c26bfdb'
        data-blockingmode='auto'
        type='text/javascript'
      ></script>

      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PCJ6T9B');`}
      </script>

      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NLQ8HT2');`}
      </script>

      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/img/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        href='/img/favicon-32x32.png'
        sizes='32x32'
      />
      <link
        rel='icon'
        type='image/png'
        href='/img/favicon-16x16.png'
        sizes='16x16'
      />

      {/* <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#fff" /> */}
      <meta name='theme-color' content='#000' />

      <meta property='og:type' content='business.business' />
      <meta property='og:title' content='Doctor Who: Time Fracture' />
      <meta property='og:url' content='http://www.immersivedoctorwho.com/' />
      <meta property='og:image' content='/img/og-image.jpg' />
    </Helmet>
  </Fragment>
);

export default App;
