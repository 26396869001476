export const LOGIN = 'line-up/auth/LOGIN';
export const LOGOUT = 'line-up/auth/LOGOUT';
export const REGISTER = 'line-up/auth/REGISTER';
export const SEND_PASSWORD_RESET = 'line-up/auth/SEND_PASSWORD_RESET';
export const SHOW_LOGIN_FORM = 'line-up/auth/SHOW_LOGIN_FORM';
export const HIDE_LOGIN_FORM = 'line-up/auth/HIDE_LOGIN_FORM';
export const SHOW_FORGOT_PASSWORD_FORM =
  'line-up/auth/SHOW_FORGOT_PASSWORD_FORM';
export const HIDE_FORGOT_PASSWORD_FORM =
  'line-up/auth/HIDE_FORGOT_PASSWORD_FORM';
export const TRACK_AFFILIATE = 'line-up/auth/TRACK_AFFILIATE';
export const LOAD_AFFILIATE = 'line-up/auth/LOAD_AFFILIATE';
export const LOAD_LOGGED_IN_CUSTOMER = 'line-up/auth/LOAD_LOGGED_IN_CUSTOMER';
