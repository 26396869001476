import { combineReducers } from 'redux';
import * as actions from './actions';
import * as types from './types';
import * as orderTypes from '../order/types';

const sessionId = (state = null, action) => {
  switch (action.type) {
    case actions.createReservation.SUCCESS:
      return action.payload.response.result;
    default:
      return state;
  }
};

const validReservation = (state = false, action) => {
  switch (action.type) {
    case actions.createReservation.TRIGGER:
    case types.EXPIRE_RESERVATION:
    case orderTypes.COMPLETE_ORDER:
    case actions.createReservation.FAILURE:
      return false;
    case actions.createReservation.SUCCESS:
      return true;
    default:
      return state;
  }
};

const creating = (state = false, action) => {
  switch (action.type) {
    case actions.createReservation.TRIGGER:
      return true;
    case actions.createReservation.SUCCESS:
    case actions.createReservation.FAILURE:
      return false;
    default:
      return state;
  }
};

const reservation = combineReducers({
  creating,
  sessionId,
  validReservation
});

export default reservation;
