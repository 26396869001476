import React from 'react';
import classNames from 'classnames';
import './TextBlock.css';

const TextBlock = ({
  children,
  className,
  textleft,
  wide,
  full,
  topmargin,
  toppadding
}) => {
  const TextBlockClass = classNames('text-block', className, {
    'text-block--text-left': textleft,
    'text-block--wide': wide,
    'text-block--full': full,
    'text-block--top-margin': topmargin,
    'text-block--top-padding': toppadding
  });

  return <div className={TextBlockClass}>{children}</div>;
};

export default TextBlock;
