import { combineReducers } from 'redux';
import * as actions from './actions';

const byId = (state = [], action) => {
  switch (action.type) {
    case actions.fetchPerformances.TRIGGER:
      return [];
    case actions.fetchPerformances.SUCCESS:
      return [...state, ...action.payload.response.result];
    case actions.fetchPerformances.FAILURE:
      return [];
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actions.fetchPerformances.TRIGGER:
      return true;
    case actions.fetchPerformances.FULFILL:
      return false;
    default:
      return state;
  }
};

const performances = combineReducers({
  byId,
  loading
});

export default performances;
