import { createAction } from 'redux-actions';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as types from './types';

export const showSpecificRequirements = createAction(
  types.SHOW_SPECIFIC_REQUIREMENTS
);
export const hideSpecificRequirements = createAction(
  types.HIDE_SPECIFIC_REQUIREMENTS
);
export const addSpecificRequirementMessage = createAction(
  types.ADD_SPECIFIC_REQUIREMENT_MESSAGE
);
export const removeSpecificRequirementMessage = createAction(
  types.REMOVE_SPECIFIC_REQUIREMENT_MESSAGE
);
export const clearSpecificRequirementMessage = createAction(
  types.CLEAR_SPECIFIC_REQUIREMENT_MESSAGE
);

export const addSpecificRequirement = createRoutine(
  types.ADD_SPECIFIC_REQUIREMENTS
);
export const submitSpecificRequirements = promisifyRoutine(
  addSpecificRequirement
);
