import React from 'react';
import classNames from 'classnames';
import './ScrollIndicator.css';

const ScrollIndicator = ({ children, className, fadein }) => {
  const ScrollIndicatorClass = classNames('scroll-indicator', className, {
    'scroll-indicator--fade-in': fadein
  });

  return <div className={ScrollIndicatorClass}>{children}</div>;
};

export default ScrollIndicator;
